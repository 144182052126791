import React, { MouseEventHandler, forwardRef } from 'react';
import { Divider, Text } from 'wix-ui-tpa/cssVars';
import {
  Comment,
  Visible,
  LikeHeart,
  LikeHeartFill,
} from '@wix/wix-ui-icons-common/on-stage';
import {
  useEnvironment,
  useBi,
  useTranslation,
  VisitorLogger,
} from '@wix/yoshi-flow-editor';
import { maMiscClickUou } from '@wix/bi-logger-members-app-uou/v2';

import {
  BlogNavigationReference,
  BlogPost,
  DataHook,
} from '../../../../../../types';
import { useSiteHandlers } from '../../../../../../contexts/widgets';
import Cover, { useCoverRenderer } from './Cover';
import { usePostRenderer } from './hooks';
import { classes, st } from './Post.st.css';

const formatDate = (dateString: string, language: string) => {
  return new Date(dateString).toLocaleDateString(language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const shouldShowPostCounters = (
  showPostViewsCounter: boolean,
  showPostCommentsCounter: boolean,
  showPostLikesCounter: boolean,
) => showPostViewsCounter || showPostCommentsCounter || showPostLikesCounter;

const reportPostClick = (bi: VisitorLogger) => {
  bi.report(maMiscClickUou({ referralInfo: BlogNavigationReference.ViewPost }));
};

export const Post = forwardRef<HTMLAnchorElement, BlogPost>(
  (
    {
      coverMedia,
      firstPublishedDate,
      minutesToRead,
      url,
      title,
      excerpt,
      postCountInfo,
    },
    ref,
  ) => {
    const bi = useBi();
    const { t } = useTranslation();
    const { language, isPreview, isMobile } = useEnvironment();
    const { shouldRenderCover } = useCoverRenderer(coverMedia);
    const {
      showPostPublishDate,
      showPostReadingTime,
      showPostExcerpt,
      showPostViewsCounter,
      showPostCommentsCounter,
      showPostLikesCounter,
    } = usePostRenderer();
    const { navigateToPath } = useSiteHandlers();
    const shouldShowCounters = shouldShowPostCounters(
      showPostViewsCounter,
      showPostCommentsCounter,
      showPostLikesCounter,
    );

    const postUrl = `${url.base}${url.path}`;
    const handlePostLinkClick: MouseEventHandler = (event) => {
      reportPostClick(bi);

      if (isPreview) {
        event.preventDefault();
        event.stopPropagation();
        navigateToPath(url.path!);
      }
    };

    return (
      <a
        ref={ref}
        data-hook={DataHook.Post}
        className={st(classes.root, { mobile: isMobile })}
        href={postUrl}
        onClick={handlePostLinkClick}
      >
        {shouldRenderCover && <Cover coverMedia={coverMedia} />}
        <div className={st(classes.content)}>
          {(showPostPublishDate || showPostReadingTime) && (
            <Text tagName="div" className={classes.dateBar}>
              {showPostPublishDate && (
                <span className={classes.text}>
                  {formatDate(firstPublishedDate, language)}
                </span>
              )}
              {showPostPublishDate && showPostReadingTime && (
                <span className={classes.text}>{' ∙ '}</span>
              )}
              {showPostReadingTime && (
                <span className={classes.text}>
                  {minutesToRead} {t('recent-posts.post-card.minutes')}
                </span>
              )}
            </Text>
          )}
          <div className={classes.postLink}>
            <Text tagName="div" className={classes.title}>
              {title}
            </Text>
            {showPostExcerpt && (
              <Text tagName="div" className={classes.excerpt}>
                {excerpt}
              </Text>
            )}
          </div>
        </div>
        {shouldShowCounters && (
          <div className={st(classes.footer)}>
            <Divider className={classes.divider} />
            <Text tagName="div" className={classes.statsBar}>
              <div className={classes.viewsAndCommentsCounter}>
                {showPostViewsCounter && (
                  <div className={classes.counter}>
                    <Visible />
                    <span>{postCountInfo.views ?? 0}</span>
                  </div>
                )}
                {showPostCommentsCounter && (
                  <div className={classes.counter}>
                    <Comment />
                    <span>{postCountInfo.comments ?? 0}</span>
                  </div>
                )}
              </div>
              {showPostLikesCounter && (
                <div className={classes.likesCounter}>
                  {postCountInfo.likes! > 0 && (
                    <span>{postCountInfo.likes}</span>
                  )}
                  {postCountInfo.likes ? <LikeHeartFill /> : <LikeHeart />}
                </div>
              )}
            </Text>
          </div>
        )}
      </a>
    );
  },
);
