import React, { FC, useRef } from 'react';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { actionButtonClicked } from '@wix/bi-logger-members-app-uou/v2';

import { DataHook } from '../../../../../types';
import { useCanEditMember, useMember } from '../../../../../contexts/widgets';
import { useModal } from '../../../../../contexts/modals';
import EmptyEditor from './EmptyEditor';
import { isAboutEmpty } from './hooks';
import { classes, st } from './About.st.css';

const AboutEditModal = loadable(() => {
  return import(
    /* webpackChunkName: "AboutEditModal" */ '../../common/AboutEditModal'
  );
});

const Viewer = loadable(
  () => import(/* webpackChunkName: "Viewer" */ './Viewer'),
);

export const About: FC = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const bi = useBi();
  const { isPreview, isMobile } = useEnvironment();
  const { canEditMember } = useCanEditMember();
  const { id, about } = useMember();
  const { showModal } = useModal();

  const shouldAllowEdit = canEditMember && (!isPreview || !isMobile);

  const openAboutEditModal = () => {
    const modalWidth = rootRef.current?.offsetWidth
      ? `${rootRef.current.offsetWidth ?? 0}px`
      : '100%';

    bi.report(
      actionButtonClicked({
        action_type: 'about_click',
        formOrigin: 'profile_page',
        member_chosen: id ?? undefined,
      }),
    );
    showModal(AboutEditModal, { width: modalWidth });
  };

  return (
    <div
      ref={rootRef}
      className={st(classes.root, { withHoverColor: shouldAllowEdit })}
    >
      {isAboutEmpty(about) ? <EmptyEditor /> : <Viewer />}
      {shouldAllowEdit && (
        <button
          type="button"
          data-hook={DataHook.AboutOverlay}
          className={classes.overlay}
          onClick={openAboutEditModal}
        />
      )}
    </div>
  );
};
