import React, { FC } from 'react';
import { Button, ButtonPriority, ThreeDotsLoader } from 'wix-ui-tpa/cssVars';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { BlogPost, DataHook } from '../../../../../types';
import { useBlogPostsHandlers } from '../../../../../contexts/widgets';
import Post from './Post';
import { usePostListFocus } from './hooks';

import { classes, st } from './PostList.st.css';

interface PostListProps {
  posts: BlogPost[];
  paging: { count: number; offset: number; total: number };
  arePostsLoading: boolean;
}

export const PostList: FC<PostListProps> = ({
  posts,
  paging,
  arePostsLoading,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { fetchPosts } = useBlogPostsHandlers();
  const { indexToFocus, focusRef } = usePostListFocus(
    arePostsLoading,
    paging.count,
  );

  const shouldShowLoadMoreButton = paging.total > paging.count;

  return (
    <div className={classes.root}>
      <div className={classes.posts}>
        {posts.map((post, index) => {
          return (
            <Post
              key={post.id}
              ref={(ref) =>
                index === indexToFocus.current && (focusRef.current = ref)
              }
              {...post}
            />
          );
        })}
      </div>
      {shouldShowLoadMoreButton && (
        <div className={classes.loadMoreButtonContainer}>
          {arePostsLoading ? (
            <ThreeDotsLoader
              data-hook={DataHook.PostsLoader}
              className={classes.loader}
            />
          ) : (
            <Button
              type="button"
              data-hook={DataHook.LoadMorePostsButton}
              className={st(classes.loadMoreButton, { mobile: isMobile })}
              priority={ButtonPriority.secondary}
              upgrade={true}
              onClick={() => {
                indexToFocus.current = paging.count;
                fetchPosts();
              }}
            >
              {t('recent-posts.load-more')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
