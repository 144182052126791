import React, { FC } from 'react';
import { Button, ButtonPriority, ButtonSize, Text } from 'wix-ui-tpa/cssVars';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { DataHook, Experiment } from '../../../../../types';
import { useBlogPosts } from '../../../../../contexts/widgets';
import settingsParams from '../../../settingsParams';
import { useCreatePostClickHandler } from '../hooks';
import { classes, st } from './EmptyState.st.css';

export const EmptyState: FC = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { createBlogPostUrl } = useBlogPosts();
  const { handleCreatePostLinkClick } = useCreatePostClickHandler();
  const { experiments } = useExperiments();
  const isBlogLiveSiteEditingEnabled = !experiments.enabled(
    Experiment.BlogLiveSiteEditingDeprecation,
  );

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <div className={classes.content}>
        <Text tagName="H2" className={classes.title}>
          {settings.get(settingsParams.blogPostsEmptyStateTitle)}
        </Text>
        {isBlogLiveSiteEditingEnabled && (
          <div className={classes.createPostLinkContainer}>
            <Button
              data-hook={DataHook.WriteYourFirstPostLink}
              className={classes.createPostButton}
              as="a"
              href={createBlogPostUrl.url}
              onClick={handleCreatePostLinkClick}
              priority={ButtonPriority.primary}
              size={ButtonSize.medium}
            >
              {settings.get(settingsParams.blogPostsEmptyStateDescription)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
