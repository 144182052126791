import { sdk as imageKitSdk } from '@wix/image-kit';
import { useSettings } from '@wix/tpa-settings/react';

import { BlogPost, PostImageFill } from '../../../../../../../types';
import settingsParams from '../../../../../settingsParams';
import { usePostWidth } from '../hooks';

const wixMediaUrlPrefix = 'https://static.wixstatic.com/media/';
const coverImageQuality = 85;

export const useCoverRenderer = (coverMedia: BlogPost['coverMedia']) => {
  const settings = useSettings();

  if (settings.get(settingsParams.showPostCover)) {
    return {
      shouldRenderCover: coverMedia?.image?.url
        ? !!coverMedia.image
        : !!coverMedia?.video?.thumbnail,
    };
  }

  return { shouldRenderCover: false };
};

export const usePostImage = () => {
  const settings = useSettings();
  const postImageFill = settings.get(settingsParams.postImageFill);
  const postImageRatio = settings.get(settingsParams.postImageRatio);
  return { postImageFill, postImageRatio };
};

export const useCover = (coverMedia: BlogPost['coverMedia']) => {
  const { postWidth } = usePostWidth();
  const { postImageFill, postImageRatio } = usePostImage();

  const cover = coverMedia?.image?.url
    ? coverMedia.image
    : coverMedia?.video?.thumbnail;
  const coverUrl = cover?.url?.replace(wixMediaUrlPrefix, '');
  const coverHeight =
    postImageFill === PostImageFill.Fill
      ? postWidth
      : Math.round(postWidth / postImageRatio);

  if (!coverUrl) {
    return { coverUrl: '', coverHeight };
  } else if (coverUrl === cover?.url) {
    return { coverUrl, coverHeight };
  }

  const buildImageUrl =
    postImageFill === PostImageFill.Fill
      ? imageKitSdk.getScaleToFillImageURL
      : imageKitSdk.getScaleToFitImageURL;

  return {
    coverUrl: buildImageUrl(
      coverUrl,
      postWidth,
      coverHeight,
      cover?.width ?? 0,
      cover?.height ?? 0,
      { quality: coverImageQuality },
    ),
    coverHeight,
  };
};
