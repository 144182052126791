import React, { FC } from 'react';
import type { Badge as BadgeType } from '@wix/ambassador-badges-v3-badge/types';

import { classes } from './Badge.st.css';

type BadgeProps = Pick<BadgeType, 'backgroundColor' | 'icon' | 'title'>;

export const Badge: FC<BadgeProps> = ({ backgroundColor, icon, title }) => {
  const style = {
    ...(backgroundColor && { backgroundColor }),
  };

  return (
    <div className={classes.root} style={style}>
      {icon && (
        <img className={classes.icon} src={icon} alt={title ?? undefined} />
      )}
    </div>
  );
};
