import { useEffect, useRef } from 'react';

export const usePostListFocus = (areLoading: boolean, currentCount: number) => {
  const prevAreLoading = useRef(areLoading);
  const indexToFocus = useRef(currentCount);
  const focusRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (prevAreLoading.current && !areLoading) {
      focusRef.current?.focus();
    }

    prevAreLoading.current = areLoading;
  }, [areLoading]);

  return { indexToFocus, focusRef };
};
