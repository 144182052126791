import React, { FC } from 'react';
import { Toast as TPAToast, ToastPlacement } from 'wix-ui-tpa/cssVars';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useUI, useUIHandlers } from '../../../../../contexts/widgets';
import { DataHook } from '../../../../../types';
import { getToastContent } from './getToastContent';
import { classes, st } from './Toast.st.css';

export const Toast: FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { toast } = useUI();
  const { hideToast } = useUIHandlers();

  const { toastContent, toastSkin } = getToastContent(toast.type, t);

  return (
    <TPAToast
      className={st(classes.toast, {
        mobile: isMobile,
        displayNone: !toast.isVisible,
      })}
      data-hook={DataHook.Toast}
      skin={toastSkin}
      placement={
        isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline
      }
      children={toastContent}
      isShown={toast.isVisible}
      shouldShowCloseButton={true}
      onClose={() => hideToast()}
    />
  );
};
