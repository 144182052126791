import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { useMember } from '../../../../contexts/widgets';
import settingsParams from '../../settingsParams';
import SectionTitle from '../common/SectionTitle';
import { Badge } from './Badge/Badge';
import { classes, st } from './Badges.st.css';

export const Badges: FC = () => {
  const { badges } = useMember();
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <>
      <SectionTitle>
        {settings.get(settingsParams.badgesSectionTitle)}
      </SectionTitle>
      <ul>
        {badges.map((badge) => (
          <li
            key={badge.id}
            className={st(classes.item, {
              mobile: isMobile,
              desktop: !isMobile,
            })}
          >
            <div className={classes.badge}>
              <Badge
                backgroundColor={badge.backgroundColor}
                icon={badge.icon}
                title={badge.title}
              />
            </div>
            <div className={classes.badgeDetails}>
              <Text
                className={st(classes.title, { mobile: isMobile })}
                tagName="div"
              >
                {badge.title}
              </Text>
              {badge.description && (
                <Text
                  className={st(classes.description, { mobile: isMobile })}
                  tagName="div"
                >
                  {badge.description}
                </Text>
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
