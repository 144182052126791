import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { SocialLink as ISocialLink } from '../../../../../types';
import { iconMap } from './icons';
import { classes, st } from './SocialLink.st.css';

export const SocialLink: FC<Omit<ISocialLink, 'id'>> = ({
  name,
  socialType,
  href,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const Icon = iconMap[socialType];

  if (!href) {
    return (
      <Text
        className={st(classes.placeholder, { mobile: isMobile })}
        tagName="div"
      >
        {Icon && <Icon className={classes.icon} />}
        {t('about.widget.not-published')}
      </Text>
    );
  }

  return (
    <a
      className={classes.link}
      href={href as string}
      rel="noreferrer"
      target="_blank"
    >
      <Text className={st(classes.text, { mobile: isMobile })} tagName="div">
        {Icon && <Icon className={classes.icon} />}
        {name}
      </Text>
    </a>
  );
};
